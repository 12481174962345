import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { hash } from "bcryptjs";
import { coreApi } from "@services/api";
import { Storage } from "@services/storage";
import { AuthUser } from "../types/AuthUser";
import { Credentials } from "../types/Credentials";
import { CredentialsAuth } from "../types/CredentialsAuth";
import { UserBranch } from "../types/UserBranch";

type RequestConfiguration = {
  _retry: boolean;
};

type AuthRequestError = {
  response: AxiosResponse & { config: RequestConfiguration };
  config: RequestConfiguration;
};

export type AuthType = {
  isReady: boolean;
  user?: AuthUser;
  usersBranches: UserBranch[];
  getApi: () => AxiosInstance | undefined;
  loadUsersBranches: (values: Credentials) => Promise<AxiosResponse<any, any>>;
  login: (values: CredentialsAuth) => Promise<AxiosResponse<any, any>>;
  logout: () => void;
};

const encryptPassword = (password: string) => hash(password, 10);

const authRequest = async ({
  email,
  password,
  clientBranchId,
}: CredentialsAuth) => {
  return coreApi.post("/user/auth", {
    email: email,
    password: await encryptPassword(password),
    clientBranchId: clientBranchId,
  });
};

const getUsersBranchesRequest = async ({ email, password }: Credentials) => {
  return coreApi.post("/user/auth/branches", {
    password: await encryptPassword(password),
    email,
  });
};

const tokenRefreshRequest = async (user: AuthUser) => {
  const requestBody = {
    email: user.email,
    clientBranchId: user.clientBranchId,
    refreshToken: user.auth.refreshToken,
  };

  return axios.post(
    `${coreApi.defaults.baseURL}/user/auth/refresh`,
    requestBody
  );
};

const isInvalidRetry = ({ response, config }: AuthRequestError) => {
  const validResponse = !response || ![200, 201, 401].includes(response.status);

  const refreshDenied = response && response.status === 401 && config?._retry;

  return validResponse || refreshDenied;
};

const onRequestFail = async ({
  user,
  error,
  onRefresh,
}: {
  user: AuthUser;
  error: AuthRequestError;
  onRefresh: ({
    email,
    ...response
  }: AxiosResponse & { email: string }) => void;
}) => {
  if (isInvalidRetry(error)) return Promise.reject(error);

  error.response.config._retry = true;

  const newUser: AuthUser = await tokenRefreshRequest(user)
    .then((response) => onRefresh({ email: user.email, ...response }))
    .catch((error) => error.response);

  if (!newUser?.auth?.token) return Promise.reject(error);

  const bearer = `bearer ${newUser.auth.token}`;

  error.response.config.headers["Authorization"] = bearer;

  return error.response.config;
};

const getApi = ({
  user,
  onRefresh,
}: {
  user?: AuthType["user"];
  onRefresh: ({
    email,
    ...response
  }: AxiosResponse & {
    email: string;
  }) => void;
}) => {
  if (!user?.id) return undefined;

  const api = axios.create({
    baseURL: coreApi.defaults.baseURL,
  });

  api.interceptors.request.use(async (config) => {
    config.headers["Authorization"] = `Bearer ${user?.auth.token}`;

    return config;
  });

  api.interceptors.response.use(
    async (response) => response,
    async (error) =>
      onRequestFail({ user, error, onRefresh }).then((config) => api(config))
  );

  return api;
};

const authStorage = new Storage<AuthType["user"]>("userAccess");

export function useAuth() {
  const [user, setUser] = useState<AuthType["user"]>(undefined);
  const [usersBranches, setUsersBranches] = useState<UserBranch[]>([]);
  const [isReady, setIsReady] = useState(false);

  const onLogout = useCallback(() => {
    setUser(undefined);
    authStorage.store(undefined);
  }, []);

  const onLoggedIn = useCallback((user: AuthUser) => {
    setUser(user);
    authStorage.store(user);
  }, []);

  const onResponseToken = useCallback(
    ({ email, status, data }: AxiosResponse & { email: string }) => {
      if (status === 401 || !data?.token) {
        onLogout();
        return;
      }

      const { token, refreshToken, user } = data;

      const auth = { token, refreshToken };

      const userData = { ...user, email, auth };

      onLoggedIn(userData);

      return userData;
    },
    [user]
  );

  useEffect(() => {
    authStorage.getStored().then((storedUser) => {
      setUser(storedUser);
      setIsReady(true);
    });
  }, []);

  return {
    isReady,
    user,
    usersBranches,
    getApi: useCallback(
      () => getApi({ user, onRefresh: onResponseToken }),
      [user]
    ),
    loadUsersBranches: async (credentials: Credentials) => {
      const response = await getUsersBranchesRequest(credentials);

      setUsersBranches(response.data as UserBranch[]);

      return response;
    },
    login: async (credentials: CredentialsAuth) =>
      authRequest(credentials).then((response) =>
        onResponseToken({ email: credentials.email, ...response })
      ),
    logout: onLogout,
  };
}
