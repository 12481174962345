import axios from "axios";

export const webRoot = axios.create({
  baseURL: "https://wiki4fit.com.br",
  // baseURL: "http://localhost",
});

export const webApi = axios.create({
  baseURL: "https://wiki4fit.com.br/api",
  // baseURL: "http://localhost/api",
});

export const coreApi = axios.create({
  baseURL: "https://integrations.wiki4fit.com.br",
  // baseURL: "http://localhost:9090",
});
