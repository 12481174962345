import { useRoute } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { LegacyAppWebView } from "@components/LegacyAppWebView";

import { internalDomain } from "../env";
import { Loading } from "./Login/styles";
import { useAppContext } from "@contexts/app";

export default function PwaPage() {
  const route = useRoute();
  const {
    auth,
    locale: { appLanguage },
  } = useAppContext();

  const [event, setEvent] = useState<{ id: String; hash: string } | undefined>(
    undefined
  );

  const [page, setPage] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!route || !auth?.user?.auth) return;

    if (!route) return;

    const params = route.params as { page: string };
    setPage(params?.page);

    const api = auth.getApi();

    if (!api) throw new Error("API not found");

    api
      .post("/app/events/login", {})
      .then((response) => setEvent(response.data.event));
  }, [route, auth]);

  if (!event?.id) return <Loading />;

  return (
    <LegacyAppWebView
      uri={`${internalDomain}?event=${event.id}&hash=${event.hash}&page=${page}&lang=${appLanguage}`}
    />
  );
}
