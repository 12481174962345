import { StatusBar } from "expo-status-bar";
import OneSignal from "react-native-onesignal";
import Constants from "expo-constants";
import { useEffect } from "react";
import { Platform } from "react-native";
import { NavigationContainer } from "@react-navigation/native";

import { Loading } from "./pages/Login/styles";
import { AppDrawerStack } from "./components/AppDrawerStack";
import { AppLoginStack } from "./components/AppLoginStack";
import { AppProvider, useAppContext } from "./contexts/app";
import { NativeBaseProvider } from "native-base";

function AppContent() {
  const {
    auth: { user, isReady },
  } = useAppContext();

  if (!isReady) return <Loading />;

  if (!user?.auth) return <AppLoginStack />;

  return <AppDrawerStack />;
}

export default function App() {
  useEffect(() => {
    if (Platform.OS === "web") return;

    if (Constants?.manifest?.extra?.oneSignalAppId) {
      OneSignal.setAppId(Constants.manifest.extra.oneSignalAppId as string);
    }
  }, []);

  return (
    <AppProvider>
      <NativeBaseProvider>
        <NavigationContainer>
          <AppContent />
        </NavigationContainer>
        <StatusBar backgroundColor="transparent" style="auto" />
      </NativeBaseProvider>
    </AppProvider>
  );
}
