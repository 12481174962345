import styled from "styled-components/native";
import { loginMainColor, loginAltColor } from "../../env";

export const Content = styled.View`
  width: min(350px, calc(100% - 40px));
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 auto;
`;

export const Input = styled.TextInput`
  width: 100%;
  height: 50px;
  background-color: ${loginMainColor};
  margin: 10px;
  padding: 8px;
  color: #222;
  font-size: 17px;
  border-radius: 4px;
`;

export const PrimaryButton = styled.TouchableOpacity`
  height: 50px;
  background-color: ${loginMainColor};
  margin: 10px;
  padding: 8px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
`;

export const SecondaryButton = styled(PrimaryButton)`
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const ButtonLabel = styled.Text`
  color: ${loginAltColor};
`;

export const Text = styled.Text`
  color: ${loginMainColor};
  text-shadow: 0 1px 3px ${loginAltColor};
  text-align: center;
`;

export const ErrorText = styled.Text`
  margin-top: 1em;
  color: red;
  font-weight: bold;
  text-align: center;
  text-shadow: 0 1px 3px black;
`;

export const Loading = styled.ActivityIndicator`
  margin-top: 1em;
`;
